<template>
  <div class="download">
    <div class="bgText" style="display: none">
      <p>选择合适的版本，开启高效工作</p>
      <p>满足不同规模项目各阶段需求，为你和你的伙伴创造更多价值</p>
    </div>
    <div class="bgVersions" style="display: none">
      <div class="bg">
        <div class="box">
          <p class="pTitle">试用版</p>
          <p class="btn">
            <span class="logo">￥</span>
            <span class="money">0</span>
            <span>/6个月</span>
          </p>
          <p class="pExplain">
            <span>使用人数</span>
            <span>1人</span>
          </p>
          <div class="function">
            <ul>
              <li><img src="../assets/images/yes.svg" alt="" />AI识图</li>
              <li><img src="../assets/images/yes.svg" alt="" />通用工具</li>
              <li><img src="../assets/images/yes.svg" alt="" />一键上传</li>
              <li><img src="../assets/images/yes.svg" alt="" />SPDCost模块</li>
            </ul>
            <ul>
              <li><img src="../assets/images/yes.svg" alt="" />土建效率工具</li>
              <li><img src="../assets/images/yes.svg" alt="" />机电效率工具</li>
              <li><img src="../assets/images/yes.svg" alt="" />出图效率工具</li>
            </ul>
          </div>
          <DownloadButton />
        </div>
        <div class="box education">
          <p class="pTitle">教育版</p>
          <p class="btn">
            <span class="logo">￥</span>
            <span class="money">128</span>
            <span>/年起</span>
          </p>
          <p class="pExplain">
            <span>使用人数</span>
            <span>100人起</span>
          </p>
          <div class="function">
            <ul>
              <li><img src="../assets/images/yes.svg" alt="" />AI识图</li>
              <li><img src="../assets/images/yes.svg" alt="" />通用工具</li>
              <li class="disabled"><span></span>一键上传-可后续购买</li>
              <li class="disabled"><span></span> SPDCost模块-可后续购买</li>
            </ul>
            <ul>
              <li><img src="../assets/images/yes.svg" alt="" />土建效率工具</li>
              <li><img src="../assets/images/yes.svg" alt="" />机电效率工具</li>
              <li><img src="../assets/images/yes.svg" alt="" />出图效率工具</li>
            </ul>
          </div>
          <DownloadButton />
        </div>
      </div>
    </div>
    <div class="support">
      <div class="versions">
        <div class="revit">
          <p>
            <img src="../assets/images/xiazai_img_logo2016.png" alt="" />
            <span>Revit 2016</span>
          </p>
          <p>
            <img src="../assets/images/xiazai_img_logo2017-2018.png" alt="" />
            <span>Revit 2017</span>
          </p>
          <p>
            <img src="../assets/images/xiazai_img_logo2017-2018.png" alt="" />
            <span>Revit 2018</span>
          </p>
          <p>
            <img src="../assets/images/xiazai_img_logo2017-2018.png" alt="" />
            <span>Revit 2019</span>
          </p>
          <p>
            <img src="../assets/images/xiazai_img_logo2017-2018.png" alt="" />
            <span>Revit 2020</span>
          </p>
          <p>
            <img src="../assets/images/xiazai_img_logo2017-2018.png" alt="" />
            <span>Revit 2021</span>
          </p>
        </div>
        <div class="line">现支持以上版本</div>
      </div>
      <div class="downloadBtn">
        <DownloadButton />
        <p><router-link :to="{ name: 'Support' }">更新日志</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadButton from '@/components/DownloadButton.vue'

export default {
  components: {
    DownloadButton
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
/* download */
.download {
  position: absolute;
  height: 100%;
  background: #f5f5f5;
  width: 100%;
  background: url('../assets/images/xiazai_bg.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.download .bgText {
  width: 100%;
  height: 365px;
  padding-top: 50px;
  background-color: #f2f1f2;
  text-align: center;
  font-family: Source Han Sans CN;
  font-size: 36px;
  color: #333;
}
.download .bgText p:last-child {
  font-size: 18px;
  margin-top: 20px;
  color: #999;
}
.download .bgVersions {
  width: 100%;
  height: 517px;
  position: relative;
}
.download .bgVersions .bg {
  width: 1280px;
  padding: 0 40px;
  position: absolute;
  top: -175px;
  left: 50%;
  margin-left: -640px;
  display: flex;
  justify-content: space-between;
}
.download .box {
  background: url('../assets/images/download_card_bg.png') no-repeat;
  background-size: 100%;
  border-radius: 3px;
  box-shadow: 0 0 30px #d5d9dc;
  border-top: 4px solid #02d6dc;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 29px;
  margin-right: 32px;
  font-size: 16px;
}
.download .box:last-child {
  margin-right: 0;
  border-top: 4px solid #36c626;
}
.download .box .pTitle {
  font-size: 24px;
}
.download .box .btn {
  width: 230px;
  height: 81px;
  background-color: #02d6dc;
  border-radius: 8px;
  font-size: 18px;
  margin-top: 22px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.download .box .btn .logo {
  line-height: 40px;
}
.download .box .btn .money {
  font-size: 50px;
  margin: 0 5px;
}
.download .box .btn span:last-child {
  line-height: 94px;
}
.download .box .pExplain {
  width: 230px;
  display: flex;
  justify-content: space-between;
}
.download .function {
  width: 388px;
  margin-top: 130px;
  display: flex;
  justify-content: space-between;
  color: #666;
}
.download .function img {
  margin-right: 16px;
  height: 15px;
}
.download .function li {
  margin-bottom: 10px;
}
.download .box .primary {
  width: 160px;
  margin-top: 35px;
  height: 40px;
}
.download .education .btn {
  background-color: #36c626;
}
.download .box .disabled {
  cursor: no-drop;
}
.download .disabled span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 16px;
  vertical-align: middle;
  background-color: #e2e2e2;
}
.download .versions {
  width: 526px;
  border-radius: 4px;
}
.download .revit {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 22px 16px 0 16px;
  margin-right: 16px;
}
.download .revit p {
  width: 154px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.download .revit span {
  margin-top: 30px;
}
.download .line {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #666666;
  position: relative;
}
.download .line::before {
  content: "";
  position: absolute;
  width: 154px;
  height: 0px;
  border-top: solid 1px #c1c1c1;
  left: 16px;
  top: 11px;
}
.download .line::after {
  content: "";
  position: absolute;
  width: 154px;
  height: 0px;
  border-top: solid 1px #c1c1c1;
  right: 16px;
  top: 11px;
}
.download button {
  width: 186px;
  height: 45px;
  margin-top: 26px;
  margin-bottom: 20px;
  font-size: 16px;
}
.download .downloadBtn {
  text-align: center;
}
.downloadBtn .primary a {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 45px;
}
.downloadBtn p a {
  color: #02d6dc;
  border-bottom: 1px solid;
}
</style>